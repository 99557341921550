import React from 'react';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import Banner from '../Elements/Banner';
import About3 from '../Elements/About3';

var bnrimg = require('./../../images/banner/modern-kitch.jpeg');

class Home4 extends React.Component {
    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/custom.js');

    };
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    <Banner title="About Us" pagename="About Us" description="At Bespoke Living Spaces, we understand that your home should be a reflection of your unique personality and lifestyle. Our dedicated team collaborates closely with you, offering personalized design consultations and guiding you through the entire process. We take the time to listen to your preferences, ensuring that every element of your dream home aligns seamlessly with your vision.." bgimage={bnrimg}/>
                    <About3 bgcolor="bg-gray" />
                    {/* <Team1 /> */}
                    
                </div>

                <Footer />
            </>
        );
    };
};

export default Home4;