import React from 'react';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import Services1 from '../Elements/Services1';


class Services extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    <Services1 />
                    
                </div>

                <Footer />
            </>
        );
    };
};

export default Services;