import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import Banner from '../Elements/Banner';
import ReactPlayer from 'react-player';
import { Helmet } from 'react-helmet';

<Helmet>
  <title>Need A Loft Converted? Let Us Help| Bespoke Living Spaces</title>
  <meta name="description" content="Unlock the hidden potential of your home with exceptional loft conversions by Bespoke Living Spaces in East Sussex, UK. " />
</Helmet>

var bnrimg = require('./../../images/banner/loftconversion-east-sussex.webp');

class LoftConversions extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                <Banner title="Loft Conversions" pagename="Loft Conversions" description="Unlock the hidden potential of your home with exceptional loft conversions by Bespoke Living Spaces in East Sussex, UK. We specialize in transforming underutilized attic spaces into stylish, functional, and versatile areas that add value and enhance your living experience." bgimage={bnrimg}/>
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-t80 p-b50 mobile-page-padding">
                        <div className="container">
                            {/* GALLERY CONTENT START */}
                            <div className="row">
                                <div className="col-lg-4 col-md-12 sticky_column">
                                    <div className="about4-section m-b20 bg-gray p-a20">
                                        <div className="widget widget_services">
                                            <h4 className="widget-title">Our Services</h4>
                                            <ul className="bg-white">
                                                <li><NavLink to={"/design-build"}>Design & Build</NavLink></li>
                                                <li><NavLink to={"/loft-conversions"}>Loft Conversions</NavLink></li>
                                                <li><NavLink to={"/construction-refurbishment"}>Construction & Refurbishment</NavLink></li>
                                                <li><NavLink to={"/outbuilding"}>Outbuildings</NavLink></li>
                                            </ul>
                                        </div>
                                        <div className="wt-icon-box-wraper left bg-white p-a15 m-b10">
                                            <NavLink to={"#"} className="btn-block">
                                                <span className="text-black m-r10"><i className="fa fa-file-pdf-o" /></span>
                                                <strong className="text-uppercase text-black">Download .PDF</strong>
                                            </NavLink>
                                        </div>
                                        <div className="about4-content m-t30">
                                            <h4>GET A FREE ESTIMATE TODAY</h4>
                                            <p>Book one of our experts to give you a free no obligation estimate!</p>
                                            <NavLink to={"/contact-us"} className="site-button-link">Contact Us</NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-12">
                                    <div className="section-content">
                                        <div className="service-single-block m-b30">
                                            <div className="sx-media">
                                                <img src={require('./../../images/gallery/loft-conversion-east-sussex.webp')} alt="" />
                                            </div>
                                            <h3 className="m-t30 sx-tilte">Loft Conversions by Bespoke Living Spaces: Transform Your Loft into a Functional Living Space</h3>
                                            <p>Unlock the untapped potential of your home with Bespoke Living Spaces' exceptional loft conversions in East Sussex, UK. Our loft conversion services are designed to transform underutilized attic spaces into stylish, functional, and versatile areas that add value and enhance your living experience. With our expert team of architects, designers, and builders, we take pride in delivering tailor-made solutions that align with your vision and lifestyle. Whether you're looking to create an extra bedroom, a home office, a playroom, or a cozy retreat, our dedicated professionals are here to turn your loft into a space you'll love to live in. Experience the beauty and functionality of a bespoke loft conversion that maximizes your home's potential while reflecting your unique style and needs.</p>
                                            <p>With our expert team of architects, designers, and builders, we take pride in delivering tailor-made loft conversion solutions that align with your vision and lifestyle. Whether you're seeking an extra bedroom, a home office, a playroom, or a cozy retreat, we have the expertise to bring your ideas to life.</p>
                                            <p>Our comprehensive loft conversion services encompass every aspect of the project, from initial planning and design to construction and finishing touches. We work closely with you throughout the process, ensuring seamless communication and personalized attention to detail.</p>
                                            <button
                                                name="get in touch"
                                                type="button"
                                                value="Submit"
                                                className="site-button btn-half"
                                                onClick={() => window.location.href = "/contact-us"}
                                            >
                                                <span>Get A Free Estimate</span>
                                            </button>
                                            <br/>
                                            <div className="sx-media">
                                                <img src={require('./../../images/gallery/loftconversion-east-sussex.webp')} alt="" />
                                            </div>
                                            <br></br>
                                            <p>At Bespoke Living Spaces, we understand the unique challenges and possibilities that come with loft conversions. Our team is well-versed in navigating building regulations and maximizing the available space to create functional and aesthetically pleasing designs. We employ the highest standards of craftsmanship and use premium materials to ensure exceptional quality and durability.</p>
                                            <p>By choosing us for your loft conversion project, you can expect a seamless and stress-free experience. Our dedicated team will handle all aspects of the construction process, including structural modifications, insulation, electrical work, plumbing, and interior finishes. We prioritize efficiency and strive to complete projects on time and within budget, without compromising on quality.</p>
                                            <p>In addition to enhancing your living space, a loft conversion can significantly increase the value of your property. It offers a cost-effective alternative to moving house while providing a personalized and unique living environment that meets your evolving needs.</p>
                                            <p>Experience the transformation of your loft space with Bespoke Living Spaces. Contact us today to schedule a consultation and discover the endless possibilities for your loft conversion project in East Sussex, UK. Let our expertise and commitment to excellence create a bespoke living space that exceeds your expectations.</p>
                                            {/* <div className="single-service-list">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-12">
                                                        <ul className="list-angle-right anchor-line">
                                                            <li><NavLink to={"/services-1"}>We provide high quality design services.</NavLink></li>
                                                            <li><NavLink to={"/services-1"}>We are confident about our projects.</NavLink></li>
                                                            <li><NavLink to={"/services-1"}>A good Work Environment For work</NavLink></li>
                                                            <li><NavLink to={"/services-1"}>Communication Skills to getting in touch</NavLink></li>
                                                            <li><NavLink to={"/services-1"}> Renovations Benefit of Service</NavLink></li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-lg-6 col-md-12">
                                                        <ul className="list-angle-right anchor-line">
                                                            <li><NavLink to={"/services-1"}>Sustainability and modern tech.</NavLink></li>
                                                            <li><NavLink to={"/services-1"}>A Career Overview opportunity Available</NavLink></li>
                                                            <li><NavLink to={"/services-1"}>Scientific Skills For getting a better result</NavLink></li>
                                                            <li><NavLink to={"/services-1"}> Historic Renovations and Restorations.</NavLink></li>
                                                            <li><NavLink to={"/services-1"}>Project on time and Latest Design</NavLink></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div> */}
                                            {/* <div className="sx-media sx-img-effect zoom-slow">
                                                <div className="sx-thum-bx sx-img-overlay1 sx-img-effect yt-thum-box">
                                                    <img src="https://img.youtube.com/vi/Oy2QIiSQT2U/0.jpg" alt=""/>
                                                    <NavLink to={"#"} className="play-now" data-toggle="modal" data-target="#myModal6">
                                                        <i className="icon fa fa-play" />
                                                        <span className="ripple" />
                                                    </NavLink>
                                                </div>
                                            </div> */}
                                        </div>
                                        <div className="sx-accordion acc-bg-gray m-b30" id="accordion5">
                                            <div className="panel sx-panel">
                                                <div className="acod-head acc-actives">
                                                    <h6 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseOne5" data-parent="#accordion5">
                                                            How do we get a free quote?
                                                            <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h6>
                                                </div>
                                                <div id="collapseOne5" className="acod-body collapse show">
                                                    <div className="acod-content p-a15"><p>You can get a free quotation by calling us or sending us an email. The fastest method would be by phone, if the phone goes to voicemail send us a text as we are most likely with another customer or working as a team to get a job done.</p></div>
                                                </div>
                                            </div>
                                            <div className="panel sx-panel">
                                                <div className="acod-head">
                                                    <h6 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseTwo5" className="collapsed" data-parent="#accordion5">
                                                            How long are quotes valid for?
                                                            <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h6>
                                                </div>
                                                <div id="collapseTwo5" className="acod-body collapse">
                                                    <div className="acod-content p-a15"><p>All our quotes last for 28 days, this allows our customers to take time to decide if they would like to use our services. After 28 days the quotation may change due to fluctuating material costs.</p></div>
                                                </div>
                                            </div>
                                            <div className="panel sx-panel">
                                                <div className="acod-head">
                                                    <h6 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseThree5" className="collapsed" data-parent="#accordion5">
                                                            What aftercare support do you offer?
                                                            <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h6>
                                                </div>
                                                <div id="collapseThree5" className="acod-body collapse">
                                                    <div className="acod-content p-a15"><p>The work carried out initially should exceed your expectations but should you have a problem, give us a call and we will be on hand to assist, we believe in 100% customer satisfaction and never want you to feel frustrated.</p></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 m-b30">
                                                <div className="sx-icon-box-wraper p-a30  center bg-white shadow">
                                                    <div className="sx-icon-box-lg inline-icon text-primary  m-b20 radius bg-secondry  scale-in-center">
                                                        <span className="icon-cell sx-text-primary"><i className="flaticon-door" /></span>
                                                    </div>
                                                    <div className="icon-content">
                                                        <h4 className="sx-tilte">Decorating</h4>
                                                        <p>The loft is converted... Now let us add some decor!</p>
                                                        <NavLink to={"/decorating-services"} className="site-button-link">View Decorating Services</NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 m-b30">
                                                <div className="sx-icon-box-wraper p-a30  center bg-white shadow">
                                                    <div className="sx-icon-box-lg inline-icon text-primary  m-b20 radius bg-secondry  scale-in-center">
                                                        <span className="icon-cell sx-text-primary"><i className="flaticon-ruler-1" /></span>
                                                    </div>
                                                    <div className="icon-content">
                                                        <h4 className="sx-tilte">Get An Estimate</h4>
                                                        <p>Are you ready to get started? Contact us today to start moving forward!</p>
                                                        <NavLink to={"/contact-us"} className="site-button-link">Contact Us</NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* GALLERY CONTENT END */}
                        </div>
                    </div>
                    {/* SECTION CONTENT END  */}
                </div>
                <div className="modal fade" id="myModal6" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <ReactPlayer url='https://www.youtube.com/watch?v=Oy2QIiSQT2U' />
                        </div>
                    </div>
                </div>

                <Footer />
            </>
        );
    };
};

export default LoftConversions;