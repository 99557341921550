import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Slider1 from './../Elements/Slider1';
import About1 from './../Elements/About1';
import WhatWeDo1 from './../Elements/WhatWeDo1';
import Testimonials1 from './../Elements/Testimonials1';
import Services1 from './../Elements/Services1';
import { Helmet } from 'react-helmet';

<Helmet>
  <title>Your Local Trusted Builder | Bespoke Living Spaces Brighton</title>
  <meta name="description" content="With over a decade of expertise in the industry, Bespoke Living Spaces is your premier choice for building, design, and structural construction services." />
</Helmet>

class Home1 extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    <Slider1 />
                    <About1 />
                    <WhatWeDo1 />
                    <Testimonials1 />
                    <Services1 />
                </div>
                <Footer />
            </>
        );
    };
};

export default Home1;