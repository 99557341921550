import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import Projects3 from './../Elements/Projects3';

var bnrimg = require('./../../images/banner/banner-4.jpeg');

class ProjectCorousel extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    <Banner title="Previous Projects" pagename="Previous Projects" description="We are so proud of what we do, take a look at some of our past projects." bgimage={bnrimg}/>
                    
                    <Projects3 alignment="separator-left" title="Completed Projects" bgcolor="bg-gray" />
                    
                </div>

                <Footer />
            </>
        );
    };
};

export default ProjectCorousel;