import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home1 from './Pages/Home1';

import About from './Pages/About2';

import Services from './Pages/Services';

import ServiceDetail from './Pages/ServiceDetail';
import DesignAndBuild from './Pages/DesignBuild';
import LoftConversions from './Pages/LoftConversions';
import ConstructionRefurb from './Pages/ConstructionRefurb';

import Error from './Pages/Error';

import ProjectCorousel from './Pages/ProjectCorousel';
import ProjectDetail1 from './Pages/ProjectDetail1';
import OrangeryExtension from './Pages/OrangeryExtension';

import Faq from './Pages/Faq';
import ContactUs from './Pages/ContactUs';
import ScrollToTop from './Common/ScrollToTop';

class Components extends React.Component {
    render() {
        return (
            <BrowserRouter basename="/">
                <ScrollToTop />
                <div className="page-wraper">
                        <Routes>
                            <Route path='/' element={<Home1/>} />

                            <Route path='/about' element={<About/>} />

                            <Route path='/services' element={<Services/>} />
                            <Route path='/design-build' element={<DesignAndBuild/>} />
                            <Route path='/loft-conversions' element={<LoftConversions/>} />
                            <Route path='/construction-refurbishment' element={<ConstructionRefurb/>}/>
                            <Route path='/services-detail' element={<ServiceDetail/>} />

                            <Route path='/error-404' element={<Error/>} />

                            <Route path='/project-carousel' element={<ProjectCorousel/>} />
                            <Route path='/project-detail1' element={<ProjectDetail1/>} />
                            <Route path='/orangery-extension' element={<OrangeryExtension/>} />

                            <Route path='/faq' element={<Faq/>} />
                            <Route path='/contact-us' element={<ContactUs/>} />
                            
                            <Route element={<Error/>} />
                        </Routes>
                </div>
            </BrowserRouter>
        );
    };
};

export default Components;