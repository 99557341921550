import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import GoogleMapReact from 'google-map-react';
import emailjs from '@emailjs/browser';

var bnrimg = require('./../../images/banner/banner-4.jpeg');

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class ContactUs extends React.Component {
    handleSubmit = (event) => {
        event.preventDefault(); // Prevent default form submission behavior
        emailjs.sendForm('service_a1ler0h', 'template_doe73m3', event.target, 'kaWj5Z6RoShLaLFtW');
    }

    render() {
        const defaultProps = {
            center: {
              lat: 34.073280,
              lng: -118.251410
            },
            zoom: 12
        };
        return (
            <>
                <Header />
                <div className="page-content">
                    <Banner title="Contact Us" pagename="Contact us" description="You can use the information and the form provided below to get in contact.We aim to respond to new enquiries within 2 working days, if your enquiry is urgent please call us." bgimage={bnrimg}/>
                    {/* SECTION CONTENTG START */}
                    <div className="section-full p-tb80 inner-page-padding">
                        {/* LOCATION BLOCK*/}
                        <div className="container">
                            {/* GOOGLE MAP & CONTACT FORM */}
                            <div className="section-content">
                                {/* CONTACT FORM*/}
                                <div className="row">
                                    <div className="col-lg-8 col-md-12 col-sm-12">
                                    <form className="contact-form cons-contact-form bg-gray p-a30" onSubmit={this.handleSubmit}>
    <div className="contact-one">
        {/* TITLE START */}
        <div className="section-head">
            <div className="sx-separator-outer separator-left">
                <div className="sx-separator bg-white bg-moving bg-repeat-x" style={{ backgroundImage: 'url(images/background/cross-line2.png)' }}>
                    <h3 className="sep-line-one">Message Us</h3>
                </div>
            </div>
        </div>
        {/* TITLE END */}
        <div className="form-group">
            <input name="name" type="text" required className="form-control" placeholder="Name" />
        </div>
        <div className="form-group">
            <input name="email" type="email" className="form-control" required placeholder="Email" />
        </div>
        <div className="form-group">
            <textarea name="number" type="tel" className="form-control" required placeholder="Contact Number" />
        </div>
        <div className="form-group">
            <textarea name="message" rows={4} className="form-control" required placeholder="Message" defaultValue={""} />
        </div>
        <div className="text-right">
            <button name="submit" type="submit" className="site-button btn-half"><span>Submit</span></button>
        </div>
    </div>
</form>

                                    </div>
                                    <div className="col-lg-4 col-md-12 col-sm-12">
                                        <div className="contact-info block-shadow bg-white bg-center p-a40" style={{ backgroundImage: 'url(images/background/bg-map.png)' }}>
                                            <div>
                                                {/* TITLE START */}
                                                <div className="section-head">
                                                    <div className="sx-separator-outer separator-left">
                                                        <div className="sx-separator bg-white bg-moving bg-repeat-x" style={{ backgroundImage: 'url(images/background/cross-line2.png)' }}>
                                                            <h3 className="sep-line-one">Info</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* TITLE END */}
                                                <div className="sx-icon-box-wraper left p-b30">
                                                    <div className="icon-xs"><i className="fa fa-phone" /></div>
                                                    <div className="icon-content">
                                                        <h5 className="m-t0">Phone number</h5>
                                                        <p>07966282564</p>
                                                    </div>
                                                </div>
                                                <div className="sx-icon-box-wraper left p-b30">
                                                    <div className="icon-xs"><i className="fa fa-envelope" /></div>
                                                    <div className="icon-content">
                                                        <h5 className="m-t0">Email address</h5>
                                                        <p>hello@bespokelivingspaces.co.uk</p>
                                                    </div>
                                                </div>
                                                <div className="sx-icon-box-wraper left">
                                                    <div className="icon-xs"><i className="fa fa-map-marker" /></div>
                                                    <div className="icon-content">
                                                        <h5 className="m-t0">Areas Covered</h5>
                                                        <p>Brighton, Worthing, Lewes, Peacehaven & Haywards Heath</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="gmap-outline">
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: "AIzaSyAfY1DRbspf6E3jYUso-PeI_tdfRXA59i0" }}
                            defaultCenter={defaultProps.center}
                            defaultZoom={defaultProps.zoom}
                            >
                            <AnyReactComponent lat={34.073280} lng={-118.251410} text={<i className="fa fa-map-marker" />}                                        />
                        </GoogleMapReact>                        
                    </div>
                    {/* SECTION CONTENT END */}
                </div>

                <Footer />
            </>
        );
    };
};

export default ContactUs;