import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import Banner from '../Elements/Banner';
import ReactPlayer from 'react-player';
import { Helmet } from 'react-helmet';

<Helmet>
  <title>Want To Refurbish Your Home or Office? Let Us Help | Bespoke Living Spaces</title>
  <meta name="description" content="Unlock the true potential of your property with Bespoke Living Spaces' premier construction and refurbishment services in East Sussex, UK." />
</Helmet>

var bnrimg = require('./../../images/banner/banner-4.jpeg');

class ConstructionRefurb extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                <Banner title="Construction & Refurbishment" pagename="Construction & Refurbishment" description="Unlock the true potential of your property with Bespoke Living Spaces' premier construction and refurbishment services in East Sussex, UK. Whether you're looking to breathe new life into your home or revitalize your commercial space, our skilled team of professionals is here to make your vision a reality. With meticulous attention to detail and a commitment to quality craftsmanship, we specialize in transforming residential and commercial properties into stunning, functional, and inspiring spaces. From concept to completion, trust us to deliver exceptional results that exceed your expectations. Experience the difference of our construction and refurbishment services and unlock the true potential of your property." bgimage={bnrimg}/>
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-t80 p-b50 mobile-page-padding">
                        <div className="container">
                            {/* GALLERY CONTENT START */}
                            <div className="row">
                                <div className="col-lg-4 col-md-12 sticky_column">
                                    <div className="about4-section m-b20 bg-gray p-a20">
                                        <div className="widget widget_services">
                                            <h4 className="widget-title">Our Services</h4>
                                            <ul className="bg-white">
                                                <li><NavLink to={"/design-build"}>Design & Build</NavLink></li>
                                                <li><NavLink to={"/loft-conversions"}>Loft Conversions</NavLink></li>
                                                <li><NavLink to={"/construction-refurbishment"}>Construction & Refurbishment</NavLink></li>
                                                <li><NavLink to={"/outbuilding"}>Outbuildings</NavLink></li>
                                            </ul>
                                        </div>
                                        <div className="wt-icon-box-wraper left bg-white p-a15 m-b10">
                                            <NavLink to={"#"} className="btn-block">
                                                <span className="text-black m-r10"><i className="fa fa-file-pdf-o" /></span>
                                                <strong className="text-uppercase text-black">Download .PDF</strong>
                                            </NavLink>
                                        </div>
                                        <div className="about4-content m-t30">
                                            <h4>GET A FREE ESTIMATE TODAY</h4>
                                            <p>Book one of our experts to give you a free no obligation estimate!</p>
                                            <NavLink to={"/contact-us"} className="site-button-link">Contact Us</NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-12">
                                    <div className="section-content">
                                        <div className="service-single-block m-b30">
                                            <div className="sx-media">
                                                <img src={require('./../../images/gallery/design-build-brighton.jpeg')} alt="" />
                                            </div>
                                            <h3 className="m-t30 sx-tilte">Transform Your Space: Premier Construction & Refurbishment Services in East Sussex, UK</h3>
                                            <p>Transform your residential or commercial property into a masterpiece with Bespoke Living Spaces' comprehensive construction and refurbishment services in East Sussex, UK. With our unwavering commitment to quality craftsmanship and attention to detail, we specialize in breathing new life into properties, creating spaces that are functional, visually stunning, and built to stand the test of time.</p>
                                            <button
                                                name="get in touch"
                                                type="button"
                                                value="Submit"
                                                className="site-button btn-half"
                                                onClick={() => window.location.href = "/contact-us"}
                                            >
                                                <span>Get A Free Estimate</span>
                                            </button>
                                            <h4 className="m-t30 sx-tilte">Unlock the Potential: Expert Construction & Refurbishment Services in East Sussex, UK</h4>
                                                    <p>Whether you're looking to build a new home from the ground up, renovate an existing property, or refurbish a commercial space, our experienced team of architects, designers, and builders are here to bring your vision to life. We work closely with you, understanding your unique requirements and aspirations, to deliver bespoke solutions that exceed your expectations.</p>
                                                    <p>From concept to completion, our dedicated professionals guide you through every stage of the construction and refurbishment process. With meticulous planning, innovative design, and expert craftsmanship, we ensure that each project is executed seamlessly, on time, and within budget.</p>
                                                    <p>At Bespoke Living Spaces, we understand the importance of creating spaces that not only meet your practical needs but also reflect your personal style and enhance your lifestyle. Whether it's a residential property or a commercial establishment, we combine functionality with aesthetic appeal to create spaces that inspire and leave a lasting impression.</p>
                                                    <p>With our extensive experience in construction and refurbishment, we have successfully transformed numerous properties in East Sussex, earning a reputation for excellence and customer satisfaction. Our dedication to delivering exceptional results, using premium materials and industry-leading techniques, sets us apart as a trusted partner in construction and refurbishment projects.</p>
                                            <div className="sx-media">
                                                <img src={require('./../../images/gallery/sussex-extension.webp')} alt="" />
                                            </div>
                                            <br></br>
                                            <p>Experience the difference of working with a construction and refurbishment specialist that prioritizes your vision, delivers outstanding quality, and exceeds your expectations. Contact Bespoke Living Spaces today to discuss your residential or commercial project in East Sussex, UK, and let us turn your property into a true masterpiece.</p>
                                        </div>
                                        <div className="sx-accordion acc-bg-gray m-b30" id="accordion5">
                                            <div className="panel sx-panel">
                                                <div className="acod-head acc-actives">
                                                    <h6 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseOne5" data-parent="#accordion5">
                                                            How do we get a free quote?
                                                            <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h6>
                                                </div>
                                                <div id="collapseOne5" className="acod-body collapse show">
                                                    <div className="acod-content p-a15"><p>You can get a free quotation by calling us or sending us an email. The fastest method would be by phone, if the phone goes to voicemail send us a text as we are most likely with another customer or working as a team to get a job done.</p></div>
                                                </div>
                                            </div>
                                            <div className="panel sx-panel">
                                                <div className="acod-head">
                                                    <h6 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseTwo5" className="collapsed" data-parent="#accordion5">
                                                            How long are quotes valid for?
                                                            <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h6>
                                                </div>
                                                <div id="collapseTwo5" className="acod-body collapse">
                                                    <div className="acod-content p-a15"><p>All our quotes last for 28 days, this allows our customers to take time to decide if they would like to use our services. After 28 days the quotation may change due to fluctuating material costs.</p></div>
                                                </div>
                                            </div>
                                            <div className="panel sx-panel">
                                                <div className="acod-head">
                                                    <h6 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseThree5" className="collapsed" data-parent="#accordion5">
                                                            What aftercare support do you offer?
                                                            <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h6>
                                                </div>
                                                <div id="collapseThree5" className="acod-body collapse">
                                                    <div className="acod-content p-a15"><p>The work carried out initially should exceed your expectations but should you have a problem, give us a call and we will be on hand to assist, we believe in 100% customer satisfaction and never want you to feel frustrated.</p></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 m-b30">
                                                <div className="sx-icon-box-wraper p-a30  center bg-white shadow">
                                                    <div className="sx-icon-box-lg inline-icon text-primary  m-b20 radius bg-secondry  scale-in-center">
                                                        <span className="icon-cell sx-text-primary"><i className="flaticon-door" /></span>
                                                    </div>
                                                    <div className="icon-content">
                                                        <h4 className="sx-tilte">Decorating</h4>
                                                        <p>The extension is built... Now let us add some colour!</p>
                                                        <NavLink to={"/decorating-services"} className="site-button-link">View Decorating Services</NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 m-b30">
                                                <div className="sx-icon-box-wraper p-a30  center bg-white shadow">
                                                    <div className="sx-icon-box-lg inline-icon text-primary  m-b20 radius bg-secondry  scale-in-center">
                                                        <span className="icon-cell sx-text-primary"><i className="flaticon-ruler-1" /></span>
                                                    </div>
                                                    <div className="icon-content">
                                                        <h4 className="sx-tilte">Solution</h4>
                                                        <p>Lorem Ipsum is simply dummy text of the printing and setting as Planning.</p>
                                                        <NavLink to={"/services-1"} className="site-button-link">Read More</NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* GALLERY CONTENT END */}
                        </div>
                    </div>
                    {/* SECTION CONTENT END  */}
                </div>
                <div className="modal fade" id="myModal6" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <ReactPlayer url='https://www.youtube.com/watch?v=Oy2QIiSQT2U' />
                        </div>
                    </div>
                </div>

                <Footer />
            </>
        );
    };
};

export default ConstructionRefurb;