import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';

var bnrimg = require('./../../images/banner/inside-after-lights.webp');

class Faq extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    <Banner title="Frequently Asked Questions" pagename="Faq" description="We get asked many questions at Bespoke Living Spaces so we have outlined some here, if you dont see an answer to your question please get in touch." bgimage={bnrimg}/>
                    {/* SECTION CONTENTG START */}
                    <div className="section-full p-t80 p-b80 inner-page-padding">
                        <div className="container">
                            <div className="faq-1">
                                {/* TITLE START */}
                                <div className="section-head">
                                    <div className="sx-separator-outer separator-left">
                                        <div className="sx-separator bg-white bg-moving bg-repeat-x" style={{ backgroundImage: 'url(images/background/cross-line2.png)' }}>
                                            <h3 className="sep-line-one">FAQ</h3>
                                        </div>
                                    </div>
                                </div>
                                {/* TITLE END */}
                                <div className="row">
                                    <div className="col-md-6 faq-list">
                                        {/* Accordian */}
                                        <div>
                                            <h4>Is an estimate a fixed price?</h4>
                                            <p>Here at Bespoke Living Spaces we always try our best to keep within budget. Our estimate is not a fixed price unfortunately. Our estimate is an approximate calculation or assessment of the likely cost, time, or quantity involved in a particular project, service, or product. It is typically based on available information and assumptions at a given point in time. An estimate provides an idea of what something might cost, but the actual price can vary depending on various factors such as changes in scope, unforeseen circumstances, market conditions, and additional requirements. For example we may remove flooring and discover rotting joists which will need resolving first which will encounter additional charges.</p>
                                        </div>
                                        <div>
                                            <h4>What payments do you accept?</h4>
                                            <p>We only accept bank transfer or cash at the moment but we are exploring other methods.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 faq-list">
                                        {/* Accordian */}
                                        <div>
                                            <h4>How long will it take to build my project?</h4>
                                            <p>Each project varies but an average project can normall take between 14 and 30 days. Our team work overtime in some cases due to unforseen circumstances such as late delivery of materials or additional works being required.</p>
                                        </div>
                                        <div>
                                            <h4>I want to start my project, how much deposit do I need?</h4>
                                            <p>We are always looking for ways to help our customers but to get started with a project under £10,000 we request a 50% deposit which covers materials, labour and access equipment if required. If your project is over £10,000 please contact us to arrange a custom payment plan.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>

                <Footer />
            </>
        );
    };
};

export default Faq;