import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import Banner from '../Elements/Banner';
import ReactPlayer from 'react-player';
import { Helmet } from 'react-helmet';

<Helmet>
  <title>Need A Builder? Let Us Help | Bespoke Living Spaces</title>
  <meta name="description" content="Unlock the hidden potential of your home Our Design & Build services are tailored to bring your dream home or commercial project to life. " />
</Helmet>

var bnrimg = require('./../../images/banner/banner-4.jpeg');

class DesignAndBuild extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                <Banner title="Design & Build" pagename="Design & Build" description="Welcome to Bespoke Living Spaces, your trusted local builder in East Sussex, UK. Our Design & Build services are tailored to bring your dream home or commercial project to life. With our expertise in design, construction, and project management, we offer comprehensive solutions that encompass every stage of your building journey. From initial concepts to the final finishing touches, we are committed to creating functional, stylish, and personalised spaces that exceed your expectations." bgimage={bnrimg}/>
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-t80 p-b50 mobile-page-padding">
                        <div className="container">
                            {/* GALLERY CONTENT START */}
                            <div className="row">
                                <div className="col-lg-4 col-md-12 sticky_column">
                                    <div className="about4-section m-b20 bg-gray p-a20">
                                        <div className="widget widget_services">
                                            <h4 className="widget-title">Our Services</h4>
                                            <ul className="bg-white">
                                                <li><NavLink to={"/design-build"}>Design & Build</NavLink></li>
                                                <li><NavLink to={"/loft-conversions"}>Loft Conversions</NavLink></li>
                                                <li><NavLink to={"/construction-refurbishment"}>Construction & Refurbishment</NavLink></li>
                                                <li><NavLink to={"/outbuilding"}>Outbuildings</NavLink></li>
                                            </ul>
                                        </div>
                                        <div className="wt-icon-box-wraper left bg-white p-a15 m-b10">
                                            <NavLink to={"#"} className="btn-block">
                                                <span className="text-black m-r10"><i className="fa fa-file-pdf-o" /></span>
                                                <strong className="text-uppercase text-black">Download .PDF</strong>
                                            </NavLink>
                                        </div>
                                        <div className="about4-content m-t30">
                                            <h4>GET A FREE ESTIMATE TODAY</h4>
                                            <p>Book one of our experts to give you a free no obligation estimate!</p>
                                            <NavLink to={"/contact-us"} className="site-button-link">Contact Us</NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-12">
                                    <div className="section-content">
                                        <div className="service-single-block m-b30">
                                            <div className="sx-media">
                                                <img src={require('./../../images/gallery/design-build-brighton.jpeg')} alt="" />
                                            </div>
                                            <h3 className="m-t30 sx-tilte">Creating beautiful spaces that our clients adore.</h3>
                                            <p>At Bespoke Living Spaces, we specialise in Design & Build services that bring your dream spaces to life. With meticulous attention to detail and a creative approach, we collaborate with you to design and construct custom-built homes, renovations, and commercial properties in East Sussex, UK. Our experienced team of architects, designers, and builders work closely with you at every step of the process, ensuring that your vision is brought to reality with impeccable craftsmanship and exceptional quality. Whether you're looking to create a contemporary masterpiece or restore a historic property, we have the expertise and dedication to exceed your expectations. Trust Bespoke Living Spaces to design and build spaces that inspire and enrich your life.</p>
                                            <button
                                                name="get in touch"
                                                type="button"
                                                value="Submit"
                                                className="site-button btn-half"
                                                onClick={() => window.location.href = "/contact-us"}
                                            >
                                                <span>Get A Free Estimate</span>
                                            </button>
                                            <h4 className="m-t30 sx-tilte">Our Design & Build Process:</h4>
                                            <div className="single-service-list">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-12">
                                                    <h6>Collaborative Design:</h6>
                                                    <p>We believe in the power of collaboration and actively involve you in the design process. Our team of skilled architects and designers work closely with you to understand your vision, preferences, and requirements. We combine your ideas with our expertise to create innovative and functional designs that reflect your unique style.</p>
                                                    <h6>Expert Construction:</h6>
                                                    <p>Our experienced builders and tradespeople bring your vision to life with expert craftsmanship. We employ high-quality materials and advanced construction techniques to ensure structural integrity, durability, and exceptional finish. Throughout the construction phase, we maintain open communication to keep you informed about the progress and address any concerns.</p>
                                                    </div>
                                                    <div className="col-lg-6 col-md-12">
                                                        <h6>Detailed Planning:</h6>
                                                        <p>A solid plan is the foundation of a successful project. We meticulously plan every aspect, considering factors such as site conditions, regulations, and budget. Our team ensures that the design aligns with your needs and the local building codes, guaranteeing a smooth and efficient construction process.</p>
                                                        <h6>Project Management:</h6>
                                                        <p>Our dedicated project managers oversee every detail of your project, ensuring seamless coordination and timely completion. They handle logistics, scheduling, and subcontractor management, minimizing disruptions and maintaining project efficiency. We strive to deliver your project within the agreed timeline and budget while upholding the highest standards of quality.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="sx-media">
                                                <img src={require('./../../images/gallery/sussex-extension.webp')} alt="" />
                                            </div>
                                            <br></br>
                                            <div className="single-service-list">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-12">
                                                    <h6>Personalised Finishes:</h6>
                                                    <p>We understand that the finishing touches are what truly make a space feel like home. Our team assists you in selecting the right materials, fixtures, and finishes that reflect your taste and enhance the functionality of your space. Whether it's flooring, cabinetry, lighting, or interior decor, we pay meticulous attention to detail to create a cohesive and aesthetically pleasing environment.</p>
                                                    </div>
                                                    <div className="col-lg-6 col-md-12">
                                                    <h6>Quality Assurance:</h6>
                                                    <p>Quality is at the core of everything we do. We adhere to stringent quality control measures throughout the Design & Build process to ensure exceptional results. From materials selection to construction techniques, we prioritize excellence and aim for your complete satisfaction with the final outcome.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="sx-accordion acc-bg-gray m-b30" id="accordion5">
                                            <div className="panel sx-panel">
                                                <div className="acod-head acc-actives">
                                                    <h6 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseOne5" data-parent="#accordion5">
                                                            How do we get a free quote?
                                                            <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h6>
                                                </div>
                                                <div id="collapseOne5" className="acod-body collapse show">
                                                    <div className="acod-content p-a15"><p>You can get a free quotation by calling us or sending us an email. The fastest method would be by phone, if the phone goes to voicemail send us a text as we are most likely with another customer or working as a team to get a job done.</p></div>
                                                </div>
                                            </div>
                                            <div className="panel sx-panel">
                                                <div className="acod-head">
                                                    <h6 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseTwo5" className="collapsed" data-parent="#accordion5">
                                                            How long are quotes valid for?
                                                            <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h6>
                                                </div>
                                                <div id="collapseTwo5" className="acod-body collapse">
                                                    <div className="acod-content p-a15"><p>All our quotes last for 28 days, this allows our customers to take time to decide if they would like to use our services. After 28 days the quotation may change due to fluctuating material costs.</p></div>
                                                </div>
                                            </div>
                                            <div className="panel sx-panel">
                                                <div className="acod-head">
                                                    <h6 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseThree5" className="collapsed" data-parent="#accordion5">
                                                            What aftercare support do you offer?
                                                            <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h6>
                                                </div>
                                                <div id="collapseThree5" className="acod-body collapse">
                                                    <div className="acod-content p-a15"><p>The work carried out initially should exceed your expectations but should you have a problem, give us a call and we will be on hand to assist, we believe in 100% customer satisfaction and never want you to feel frustrated.</p></div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="row">
                                            <div className="col-md-6 m-b30">
                                                <div className="sx-icon-box-wraper p-a30  center bg-white shadow">
                                                    <div className="sx-icon-box-lg inline-icon text-primary  m-b20 radius bg-secondry  scale-in-center">
                                                        <span className="icon-cell sx-text-primary"><i className="flaticon-door" /></span>
                                                    </div>
                                                    <div className="icon-content">
                                                        <h4 className="sx-tilte">Decorating</h4>
                                                        <p>The extension is built... Now let us add some colour!</p>
                                                        <NavLink to={"/decorating-services"} className="site-button-link">View Decorating Services</NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 m-b30">
                                                <div className="sx-icon-box-wraper p-a30  center bg-white shadow">
                                                    <div className="sx-icon-box-lg inline-icon text-primary  m-b20 radius bg-secondry  scale-in-center">
                                                        <span className="icon-cell sx-text-primary"><i className="flaticon-ruler-1" /></span>
                                                    </div>
                                                    <div className="icon-content">
                                                        <h4 className="sx-tilte">Solution</h4>
                                                        <p>Lorem Ipsum is simply dummy text of the printing and setting as Planning.</p>
                                                        <NavLink to={"/services-1"} className="site-button-link">Read More</NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            {/* GALLERY CONTENT END */}
                        </div>
                    </div>
                    {/* SECTION CONTENT END  */}
                </div>
                <div className="modal fade" id="myModal6" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <ReactPlayer url='https://www.youtube.com/watch?v=Oy2QIiSQT2U' />
                        </div>
                    </div>
                </div>

                <Footer />
            </>
        );
    };
};

export default DesignAndBuild;