import React from 'react';
import CountUp from 'react-countup';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const testimonials = [
    {
        image: require('./../../images/testimonials/pic1.jpg'),
        reviewername: 'Miss L Catlin',
        position: 'Construction & Refurb, Brighton',
        review: '“Nathan and his team did an excellent job at our property in Brighton, what was once a dingey little kitchen has been opened up to create an abundance of space. Mark did all the plumbing while Jamie & Kyle worked on the construction. The end result is amazing and they did it in under the time they predicted!”'
    },
    {
        image: require('./../../images/testimonials/pic2.jpg'),
        reviewername: 'Ms. G Gorey',
        position: 'Desing & Build, Hove',
        review: '“We had builders in before which was left in a terrible state. I called Bespoke for a quotation and they were nothing but compassionate and helpful, I accepted their estimate after a few days and they got to work the very next week! My kitchen and downstairs WC is finished and it looks incredible! Will use them again without a doubt.”'
    }
]

var bgimg2 = require('./../../images/background/bg-5.png');
var bgimg3 = require('./../../images/background/cross-line2.png');
var bgimg4 = require('./../../images/background/bg-12.webp');

class Testimonials1 extends React.Component {
    render() {
        const options = {
            loop: true,
            autoplay: true,
            margin: 30,
            autoplayTimeout: 6000,
            nav: false,
            dots: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive: {
                0: {
                    items: 1
                },
                991: {
                    items: 1
                }
            }
        };
        return (
            <>
                <div className="section-full container-fluid">
                    <div className="section-content">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-12 bg-gray bg-cover bg-no-repeat" >
                                <div className="sx-left-part mobile-page-padding">
                                    {/* TITLE START */}
                                    <div className="section-head">
                                        <div className="sx-separator-outer separator-left">
                                            <div className="sx-separator bg-white bg-moving bg-repeat-x" style={{ backgroundImage: 'url(' + bgimg3 + ')' }}>
                                                <h3 className="sep-line-one">Our Stats</h3>
                                            </div>
                                        </div>
                                    </div>
                                    {/* TITLE END */}
                                    <div className="counter-blocks">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 m-b30">
                                                <div className="sx-count  text-black sx-icon-box-wraper bg-repeat  bg-white p-a30" style={{ backgroundImage: 'url(' + bgimg2 + ')' }}>
                                                    <h2 className="st-count-number sx-text-primary text-left"><span className="counter"><CountUp end={12} duration={5} /></span></h2>
                                                    <h4 className="m-tb0">Years of Experience</h4>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 m-b30">
                                                <div className="sx-count text-black sx-icon-box-wraper bg-repeat  bg-white p-a30" style={{ backgroundImage: 'url(' + bgimg2 + ')' }}>
                                                    <h2 className="st-count-number sx-text-primary text-left"><span className="counter"><CountUp end={163} duration={5} /></span></h2>
                                                    <h4 className="m-tb0">Project Taken</h4>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 m-b30">
                                                <div className="sx-count text-black sx-icon-box-wraper bg-repeat  bg-white p-a30" style={{ backgroundImage: 'url(' + bgimg2 + ')' }}>
                                                    <h2 className="st-count-number sx-text-primary text-left"><span className="counter"><CountUp end={6} duration={5} /></span></h2>
                                                    <h4 className="m-tb0">Members Of The Team</h4>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 m-b30">
                                                <div className="sx-count text-black sx-icon-box-wraper bg-repeat  bg-white p-a30" style={{ backgroundImage: 'url(' + bgimg2 + ')' }}>
                                                    <h2 className="st-count-number sx-text-primary text-left"><span className="counter"><CountUp end={98} duration={5} /></span><span>%</span></h2>
                                                    <h4 className="m-tb0">Customer Satisfaction</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 bg-white bg-repeat" style={{ backgroundImage: 'url(' + bgimg4 + ')' }}>
                                <div className="sx-right-part mobile-page-padding  p-t80 p-b50">
                                    {/* TITLE START */}
                                    <div className="section-head">
                                        <div className="sx-separator-outer separator-left">
                                            <div className="sx-separator bg-white bg-moving bg-repeat-x" style={{ backgroundImage: 'url(' + bgimg3 + ')' }}>
                                                <h3 className="sep-line-one">Testimonials</h3>
                                            </div>
                                        </div>
                                    </div>
                                    {/* TITLE END */}
                                    {/* TESTIMONIAL START */}
                                    <OwlCarousel className="owl-carousel testimonial-home number-slider" {...options}>
                                        {testimonials.map((item, index) => (
                                            <div className="item" key={index}>
                                                <div className="testimonial-2  hover-animation-1">
                                                    <div className="testimonial-detail clearfix">
                                                        <div className="testimonial-pic shadow scale-in-center"><img src={item.image} alt="" width={100} height={100} /></div>
                                                        <h4 className="testimonial-name">{item.reviewername}</h4>
                                                        <span className="testimonial-position">{item.position}</span>
                                                        <span className="fa fa-quote-right" />
                                                    </div>
                                                    <div className="testimonial-text bg-white  shadow-sm">
                                                        <p>{item.review}</p>
                                                    </div>
                                                </div>
                                            </div>

                                        ))}

                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Testimonials1;