import React from 'react';
import { NavLink } from 'react-router-dom';
import ReactPlayer from 'react-player';

var bgimg1 = require('./../../images/inside-whatwd.webp');

class About3 extends React.Component {
    render() {
        return (
            <>
                <div className={`${this.props.bgcolor} section-full mobile-page-padding p-t80 p-b50`}>
                    <div className="container">
                        <div className="section-content">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-12">
                                    <div className="about-home-3 m-b30 bg-white">
                                        <h3 className="m-t0 m-b20 sx-tilte">Unparalleled Experience and Expertise</h3>
                                        <p>Backed by more than 10 years of experience, our team of skilled professionals brings a wealth of knowledge and proficiency to every project we undertake. From the initial concept to the final touches, we employ cutting-edge techniques and innovative solutions to ensure exceptional quality and precision in all aspects of our work.</p>
                                        <ul className="list-angle-right anchor-line">
                                            <li><NavLink to="/loft-conversions">Utilise that dead space in your loft with a conversion.</NavLink></li>
                                            <li><NavLink to="/decorating">Looking to add a splash of colour but want a crisp finish?</NavLink></li>
                                            <li><NavLink to="/extensions">Why move? Create more living space with a beautiful extension.</NavLink></li>
                                            <li><NavLink to="/contact-us">Looking for a free estimate? Get in contact now.</NavLink></li>
                                        </ul>
                                        <div className="text-left">
                                            <NavLink to="/about-1" className="site-button btn-half"><span>View Our Services</span></NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12">
                                    <div className="video-section-full-v2">
                                        <div className="video-section-full bg-no-repeat bg-cover bg-center overlay-wraper m-b30" style={{ backgroundImage: 'url(' + bgimg1 + ')' }}>
                                            <div className="overlay-main bg-black opacity-04" />
                                            {/* <div className="video-section-inner">
                                                <div className="video-section-content">
                                                    <NavLink to={"#"} className="play-now" data-toggle="modal" data-target="#myModal">
                                                        <i className="icon fa fa-play" />
                                                        <span className="ripple" />
                                                    </NavLink>

                                                    <div className="video-section-bottom">
                                                        <h3 className="sx-title text-white">10 Years+<br />Experience</h3>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="myModal" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <ReactPlayer url='https://vimeo.com/34741214' />
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default About3;