import React from 'react';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import Banner from '../Elements/Banner';
import { Helmet } from 'react-helmet';

<Helmet>
  <title>Want An Orangery Extension? Let Us Help | Bespoke Living Spaces</title>
  <meta name="description" content="Unlock the hidden potential of your home with exceptional loft conversions by Bespoke Living Spaces in East Sussex, UK. " />
</Helmet>

var bnrimg = require('./../../images/banner/inside-after-lights.webp');

class OrangeryExtension extends React.Component {
    
    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/custom.js');

    };
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    <Banner title="Orangery Extension" pagename="Orangery Extension" description="Transforming excess garden space into a large orangery extension allowed our clients to have a larger hosting area for guests and their growing family." bgimage={bnrimg} />

                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb80 inner-page-padding stick_in_parent">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-7 col-md-7  sticky_column">
                                    <div className="project-detail-containt">
                                        <div className="bg-white text-black">
                                            <h3>A multitask profession which creates any land in beautiful creation </h3>
                                            <p>We recently completed a remarkable project: an orangery extension with a stunning lantern design. This addition has significantly transformed our client's space, providing them with ample room for various purposes. The expanded area now serves as an ideal venue for hosting gatherings and accommodating family members comfortably.</p>
                                            <p>Our orangery extension project was a remarkable endeavor that involved creating a spacious and versatile living area for our client. The centerpiece of the extension is a beautifully designed lantern, which adds an elegant touch to the space.</p>
                                            <p>The lantern allows an abundance of natural light to flood the orangery, creating a bright and inviting atmosphere. This not only enhances the aesthetics of the room but also creates a seamless connection with the outdoor surroundings.</p>
                                            <p>The additional space provided by the extension has proven to be a game-changer for our client. They now have a dedicated area for hosting gatherings and entertaining guests. Whether it's a cozy family dinner or a lively social gathering, the orangery offers the perfect setting.</p>
                                            <p>Furthermore, the extension has significantly increased the functionality of our client's home. They now have the flexibility to use the space for various activities, such as a relaxation area, a home office, or a playroom for the kids. It has truly transformed their living experience and enriched their daily lives.</p>
                                            <p>Overall, the orangery extension with the lantern has not only expanded the physical space but has also brought a sense of elegance, versatility, and joy to our client's home. It is a testament to our commitment to delivering exceptional designs that perfectly align with our clients' needs and aspirations.</p>
                                            <div className="product-block">
                                                <ul>
                                                    <li>
                                                        <h4 className="m-b10">Date</h4>
                                                        <p>16th December 2022</p>
                                                    </li>
                                                    <li>
                                                        <h4 className="m-b10">Client</h4>
                                                        <p>Mr & Mrs Barnett</p>
                                                    </li>
                                                    <li>
                                                        <h4 className="m-b10">Project type</h4>
                                                        <p>Contruction & Refurbishment</p>
                                                    </li>
                                                    <li>
                                                        <h4 className="m-b10">Location</h4>
                                                        <p>Crawley, West Sussex</p>
                                                    </li>
                                                    <li>
                                                        <h4 className="m-b10">Year</h4>
                                                        <p>2022</p>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="m-b0">
                                                <div className="sx-divider divider-1px  bg-black"><i className="icon-dot c-square" /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-5 ">
                                    <div className="project-detail-outer">
                                        <div className="project-detail-pic m-b30">
                                            <div className="sx-media">
                                                <img src={require('./../../images/projects/portrait/orangery-ext.jpeg')} alt="" />
                                            </div>
                                        </div>
                                        <div className="project-detail-pic m-b30">
                                            <div className="sx-media">
                                                <img src={require('./../../images/projects/portrait/orangery-ext-outside.jpeg')} alt="" />
                                            </div>
                                        </div>
                                        <div className="project-detail-pic m-b30">
                                            <div className="sx-media">
                                                <img src={require('./../../images/projects/portrait/orangery-bifold.jpeg')} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END  */}
                    
                </div>
                <Footer />
            </>
        );
    };
};

export default OrangeryExtension;